.parent {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f2efeb;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .parent {
    justify-content: flex-start;
    align-items: center;
  }
}
.homePage {
  background: none;
  width: 100%;
  display: flex;
  overflow: hidden;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.navigationBar:global(.__wab_instance) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  max-width: 1200px;
  object-fit: cover;
  position: relative;
  padding: 16px;
}
.link__pd2J {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__nPrL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__t2CJv {
  display: block;
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 100%;
  color: #3d403a;
  position: relative;
  top: auto;
  left: auto;
  transform: translate(-0.45000000000000284px, 0.02499999999999858px);
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__t2CJv {
    left: auto;
    top: auto;
  }
}
.text__o5Oq {
  display: block;
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 1.5;
  color: #3d403a;
  position: relative;
  top: auto;
  left: auto;
  font-family: "Karla", sans-serif;
}
.img___0CmYh {
  object-fit: cover;
}
.img___0CmYh > picture > img {
  object-fit: cover;
}
.img__wPyU0 {
  object-fit: cover;
}
.img__wPyU0 > picture > img {
  object-fit: cover;
}
.link__eUIsQ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #3d403a;
  font-family: "Karla", sans-serif;
}
.link__swElq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #3d403a;
  display: none;
}
.link__laq9U {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #3d403a;
  font-family: "Karla", sans-serif;
}
.freeBox__qDpgw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-top: 0px;
  min-height: 90vh;
  min-width: 0;
  padding: 8px;
}
.freeBox__iTmf4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
  padding: 16px;
}
.text__oqqNq {
  position: relative;
  left: auto;
  top: auto;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 90px;
  color: #3d403a;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__oqqNq {
    font-size: 60px;
    left: auto;
    top: auto;
  }
}
.text__cOkxf {
  position: relative;
  font-size: 2em;
  color: #3d403a;
  margin-top: 35px;
  font-family: "Karla", sans-serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cOkxf {
    left: auto;
    top: auto;
    width: 354px;
    font-size: 30px;
  }
}
.bio {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 200px;
}
.columns__sZbpG {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  left: auto;
  top: auto;
  max-width: 1200px;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns__sZbpG > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
}
.columns__sZbpG > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns__sZbpG > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__sZbpG {
    width: 100%;
    height: auto;
    flex-direction: row;
    left: auto;
    top: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__sZbpG > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
    margin-top: calc(0px - 50px);
    height: calc(100% + 50px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__sZbpG > :global(.__wab_flex-container) > *,
  .columns__sZbpG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__sZbpG > :global(.__wab_flex-container) > picture > img,
  .columns__sZbpG
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 50px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__sZbpG > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__pwl5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: 15px 8px 8px;
}
.text___32TtK {
  position: relative;
  width: 100%;
  color: #3d403a;
  font-weight: 400;
  font-size: 1.25em;
  font-family: "Karla", sans-serif;
  padding-top: 0px;
  min-width: 0;
}
.column___6Pd32 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img___3Q8I {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 590px;
  flex-shrink: 0;
  border-radius: 25px;
}
.img___3Q8I > picture > img {
  object-fit: cover;
}
.freeBox__lkm6S {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__lkm6S {
    margin-top: 50px;
  }
}
.freeBox___0BLr1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___0BLr1 {
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
.contactSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ffZr8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
}
.columns___9HAer {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 8px;
}
.columns___9HAer > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns___9HAer > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___9HAer {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___9HAer > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__niS1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__xWxCw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.5em;
  color: #3d403a;
  font-weight: 600;
  align-self: flex-start;
  text-align: center;
  padding-bottom: 15px;
  font-family: "Karla", sans-serif;
  min-width: 0;
}
.img___5Pyuu {
  position: relative;
  object-fit: cover;
  max-width: 30%;
  align-self: center;
}
.img___5Pyuu > picture > img {
  object-fit: cover;
}
.text__z1Ldx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 1.25em;
  color: #3d403a;
  font-weight: 600;
  align-self: flex-start;
  text-align: center;
  padding-bottom: 15px;
  font-family: "Karla", sans-serif;
  min-width: 0;
}
.column__kcFrf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.freeBox__kpFpG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.freeBox__kpFpG > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__kpFpG > :global(.__wab_flex-container) > *,
.freeBox__kpFpG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kpFpG > :global(.__wab_flex-container) > picture > img,
.freeBox__kpFpG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.name:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 300px;
}
.svg__nhXqh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__k0B {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 300px;
}
.svg__hIWk9 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___4Vh4W {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.message:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 300px;
  margin-bottom: 15px;
}
.svg__kblGw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mmF4R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.submitButton:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  margin: 10px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .submitButton:global(.__wab_instance) {
    max-width: 69px;
    width: 100%;
    min-width: 0;
    flex-shrink: 0;
  }
}
.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1200px;
  min-width: 0;
  padding: 8px;
  border-top: 1px solid #3d403a;
}
.text__mWpag {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 221px;
  align-self: flex-end;
  text-align: right;
  color: #3d403a;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2em;
  min-width: 0;
}
.freeBox__moR7S {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__moR7S > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__moR7S > :global(.__wab_flex-container) > *,
.freeBox__moR7S > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__moR7S > :global(.__wab_flex-container) > picture > img,
.freeBox__moR7S
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.link__dCf9T {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.75em;
  color: #3d403a;
  font-family: "Karla", sans-serif;
}
.link__td3Gl {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.75em;
  color: #3d403a;
  font-family: "Karla", sans-serif;
  display: none;
}
.link__ioBHm {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-size: 0.75em;
  color: #3d403a;
  font-family: "Karla", sans-serif;
}
