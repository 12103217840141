.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 15%;
  background: #d9c9ba;
  height: 30px;
  position: relative;
  min-width: 0;
  border-radius: 40px;
  padding: 8px;
}
.rootsubmittedStatus {
  max-width: 300px;
  height: auto;
  background: #4fb2aa;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  font-family: "Karla", sans-serif;
  min-width: 0;
}
.textsubmittedStatus {
  font-size: 1em;
  font-weight: 500;
  width: 100%;
  color: #ffffff;
  min-width: 0;
}
