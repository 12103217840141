.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  background: #d9c9ba14;
  position: sticky;
  min-width: 0;
  border-radius: 6px;
  padding: 7px 11px;
  border: 1px solid #d9c9baad;
}
.rootcolor_dark {
  background: #232320;
  border-color: #717069;
}
.root:hover {
  border-color: #c8c7c1;
}
.root:focus-within {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
  border-radius: 6px;
}
.root___focusVisibleWithin {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
  border-radius: 6px;
}
.startIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-right: 8px;
  display: none;
}
.startIconContainershowStartIcon {
  display: flex;
}
.root .startIconContainer___focusVisibleWithin {
  outline: none;
}
.slotTargetStartIconshowStartIcon {
  color: #90908c;
}
.slotTargetStartIconcolor_dark {
  color: #ffffff;
}
.svg___2PuOp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.input {
  width: 100%;
  left: auto;
  top: auto;
  background: #d9c9ba00;
  color: #2a3e92;
  font-weight: 300;
  font-size: 1em;
  min-width: 0;
  padding: 0px;
  border-width: 0px;
}
.inputisDisabled {
  cursor: not-allowed;
}
.inputcolor_dark {
  color: #ffffff;
}
.root:focus-within .input {
  outline: none;
}
.root .input___focusVisibleWithin {
  outline: none;
}
.root .input:focus {
  outline: none;
}
.root:focus-within .input:focus {
  outline: none;
}
.root .input::placeholder {
  color: #3d403a;
  font-family: "Karla", sans-serif;
}
.rootcolor_dark .inputcolor_dark::placeholder {
  color: #c8c7c1;
}
.root:focus-within .input::placeholder {
  outline: none;
}
.endIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-left: 8px;
  display: none;
}
.endIconContainershowEndIcon {
  display: flex;
}
.slotTargetEndIconshowEndIcon {
  color: #90908c;
}
.slotTargetEndIconcolor_dark {
  color: #ffffff;
}
.svg__xgLmR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
